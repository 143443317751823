




















































import axios from 'axios';
import { get, sortBy, groupBy, forEach } from 'lodash';

import CoMenuItem from '@/components/Molecules/co-menu-item/CoMenuItem.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import Bugsnag from '@bugsnag/js';

export default {
    name: 'ListChannels',
    components: {
        CoMenuItem,
        CoThumbnail,
    },
    props: {
        circlesOn: {
            type: Boolean,
            default: false,
            required: false,
        },
        currentSpaceID: {
            type: String,
            default: '',
            required: false,
        },
    },
    data() {
        return {
            channels: [],
        };
    },
    mounted() {
        if (this.circlesOn) {
            this.listChannelsForCircle();
        } else {
            this.listChannels();
        }
    },
    methods: {
        listChannelsForCircle() {
            axios
                .post('/channel/list/circles')
                .then((response) => {
                    var channels = get(response, 'data.Objects', []);

                    // map channels to menu items by adding URL and name
                    channels = channels.map((channel) => ({
                        id: channel.object.ID,
                        url: `/channels/${channel.object.Slug}?id=${channel.object.ID}`,
                        name: channel.object.Value,
                        space: channel.space,
                    }));

                    // sort channels alphabetically by spacename
                    channels = sortBy(channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                    // group channels by spaceid
                    const groups = groupBy(channels, (channel) => channel.space.ID);
                    // reset channels and collect and sort the circle channels alphabetically
                    channels = [];
                    forEach(groups, (value, key) => {
                        if (key !== this.currentSpaceID) {
                            channels = channels.concat(value);
                        }
                    });
                    channels = sortBy(channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                    // prepend the local channels to the beginning of the array
                    this.channels = groups[this.currentSpaceID].concat(channels);
                })
                .catch((error) => {
                    Bugsnag.notify(error);
                });
        },
        listChannels() {
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        const channels = response.data.objects;
                        // map channels to menu items by adding URL and name
                        this.channels = channels.map((channel) => ({
                            url: `/channels/${channel.object.Slug}`,
                            name: channel.object.Value,
                        }));

                        // sort channels alphabetically by name using lodash
                        this.channels = sortBy(this.channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                    }
                })
                .catch((error) => {
                    Bugsnag.notify(error);
                });
        },
    },
};
