






























import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoModal from '../../Organisms/co-modal/CoModal.vue';

export default {
    components: { CoModal, CoButton },
    name: 'CoConfirmation',
    props: {
        message: {
            type: String,
        },
        confirmVariant: {
            type: String,
            default: 'default',
        },
        confirmLabel: {
            type: String,
            default: null,
        },
        cancelLabel: {
            type: String,
            default: null,
        },
        modalSize: {
            type: String,
            default: 'sm',
        },
        disableConfirm: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        // If the default slot is used, add a click event listener to the first element
        if (this.$slots.default && this.$slots.default.length > 0) {
            this.$slots.default[0].elm.addEventListener('click', this.show);
        }
    },
    methods: {
        show() {
            this.$refs.confirmationModal.show();
        },
        confirm() {
            this.$emit('confirm');
            this.$refs.confirmationModal.hide();
        },
        cancel() {
            this.$emit('cancel');
            this.$refs.confirmationModal.hide();
        },
    },
};
