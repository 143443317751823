<template>
    <div id="app" :class="{ visible: this.$store.state.isInMobile || appVisible }">
        <!-- <notifications group="app" animation-type="velocity"/> -->
        <notifications group="app" classes="vue-app-notification" animation-type="velocity" />
        <CoTopNavBarMobile
            v-if="this.$store.state.isInMobile && this.$store.state.space && this.$store.state.me"
            :logo="this.$store.state.space.LogoURL"
            :spaceName="this.$store.state.space.Name"
            :userProfilePicture="this.$store.state.me.Profile.ImageURL"
            :isHome="homePaths.includes(this.$route.path)"
            @goBack="goBack"
            @showPauseNotificationsModal=""
            @logout="logOut"
        ></CoTopNavBarMobile>
        <router-view />
        <NotificationsCenter />
        <footer>
            <CookieBanner v-if="!isSaasRoute" :privacyPolicy="PrivacyPolicy" />
        </footer>
    </div>
</template>

<style lang="less">
#app {
    /* height: 100vh; */
    opacity: 0;
    transition: opacity 0.3s;

    &.visible {
        opacity: 1;
    }
}

#birdeatsbug-sdk {
    --distance-to-window-edge: 80px;
}
</style>
<script>
import axios from 'axios';
import CookieLaw from 'vue-cookie-law';
import { get } from 'lodash';
import CookieBanner from '@/components/CookieBanner.vue';
import { EventBus } from './eventBus.js';
import setCustomColors from './customColors';

import CoTopNavBarMobile from './components/Organisms/co-top-nav-bar-mobile/CoTopNavBarMobile.vue';

export default {
    name: 'App',
    components: {
        CookieBanner,
        CookieLaw,
        CoTopNavBarMobile,
    },
    data() {
        return {
            me: null,
            appVisible: false,
            defaultColors: [
                {
                    name: 'purple',
                    value: '#5100ff',
                },
                {
                    name: 'red',
                    value: '#fd6969',
                },
                {
                    name: 'text',
                    value: '#212529',
                },
            ],
            homePaths: ['', '/', '/channels', '/booking-calendar', '/events', '/market', '/jobs'],

            PrivacyPolicy: null,
        };
    },
    async beforeCreate() {
        if (this.$route.query.m === 'mobile') {
            this.$store.commit('SET_IS_IN_MOBILE', true);
        } else if (this.$route.query.m === 'desktop') {
            this.$store.commit('SET_IS_IN_MOBILE', false);
        }

        if (
            this.$store &&
            this.$store.state &&
            this.$store.state.space &&
            !this.$router.currentRoute.path.startsWith('/admin')
        ) {
            const colorData = [
                {
                    name: 'purple',
                    value: this.$store.state.space.PrimaryColor,
                },
                {
                    name: 'red',
                    value: this.$store.state.space.SecondaryColor,
                },
                {
                    name: 'text',
                    value: this.$store.state.space.TextColor,
                },
            ];
            setCustomColors(colorData);
        }

        await this.$store
            .dispatch('getSpaceSettings', null)
            .then((response) => {
                this.setFavicon();
                if (
                    this.$store &&
                    this.$store.state &&
                    this.$store.state.space &&
                    !this.$router.currentRoute.path.startsWith('/admin')
                ) {
                    const colorData = [
                        {
                            name: 'purple',
                            value: this.$store.state.space.PrimaryColor,
                        },
                        {
                            name: 'red',
                            value: this.$store.state.space.SecondaryColor,
                        },
                        {
                            name: 'text',
                            value: this.$store.state.space.TextColor,
                        },
                    ];
                    setCustomColors(colorData);
                }
                // update unleash context and start
                this.$unleash.updateContext({
                    userId: this.$store.state.me.ID,
                    remoteAddress: this.$store.state.space.ID, // instead of remoteAddress we use spaceId
                    properties: {
                        spaceID: this.$store.state.space.ID,
                    },
                });
                // Start the background polling
                this.$unleash.start();

                // Check if feature flaging system is available
                this.$unleash.on('ready', () => {
                    if (this.$unleash.isEnabled('frontend.always-on')) {
                        console.info('frontend.always-on is enabled');
                    } else {
                        console.info('frontend.always-on is disabled');
                    }

                    if (this.$unleash.isEnabled('circles.frontend')) {
                        this.$store.commit('SET_CIRCLES_ON', true);
                    }
                });
                // update app visible
                this.appVisible = true;
            })
            .catch((error) => {
                this.appVisible = true;
            })
            .finally(() => {
                this.appVisible = true;
            });
    },
    created() {
        this.getPrivacyPolicy();
    },
    computed: {
        isSaasRoute() {
            const match = get(window, 'location.hostname', '').match(/^app|staging-app\.(coapp\.io|localhost)/gi);
            return !!(match && match.length > 0);
        },
    },
    mounted() {
        this.injectCustomerJavaScript();

        if (this.isLocalStorage()) {
            this.$store.commit('LOCALSTORAGE_TRUE');
        }
        this.getPaymentState();

        this.getPermissionSettings();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        logOut() {
            localStorage.removeItem('_authtoken');
            this.$router.go('/login');
        },
        setFavicon() {
            const faviconurl = get(this.$store, 'state.space.FaviconURL', null);
            if (faviconurl) {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                // icon.value = this.$store.state.space.Favicon;
                link.href = faviconurl;
            } else {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                link.href = '/favicon.ico';
            }
        },
        // isLocalStorage checks if local storage available to detect wheather it is CNA popup or not
        isLocalStorage() {
            const test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
                // available
            } catch (e) {
                return false;
                // unavailable
            }
        },

        getPrivacyPolicy() {
            axios({
                method: 'GET',
                url: '/space/get-privacy-policy',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.PrivacyPolicy = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getPermissionSettings() {
            axios({
                method: 'GET',
                url: '/space/permissions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const permissions = response.data;
                        this.$store.commit('SET_PERMISSIONS', permissions);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        // getPaymentState - returns payment service readyness state
        getPaymentState() {
            axios({
                method: 'GET',
                url: '/user/account/billing/payment-state',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$store.commit('SET_PAYMENT_READY_STATE', response.data.ChargesEnabled);
                    } else {
                        this.$store.commit('SET_PAYMENT_READY_STATE', false);
                    }
                })
                .catch((error) => {
                    this.$store.commit('SET_PAYMENT_READY_STATE', false);
                });
        },
        /*
    injectCustomerJavaScript

    Known issues:
    when user state changes from unknown to logged in without App.vue being newly mounted,
    placeholders will remain in unknown user state, result {{placeholders}} are not populated with data

    Description:
    injects customer defined JavaScript with placeholders in <head> with {{placeholders}} populated with user profile data, when available.
    When called in mounted this.$store.state.space and this.$store.state.me are available.
    */
        injectCustomerJavaScript() {
            if (this.$store.state.isInMobile) {
                return;
            }

            let javascript = this.$store.state.space.Javascript;
            const user = this.$store.state.me;

            if (javascript) {
                if (user.Email) {
                    javascript = javascript.replaceAll('{{name}}', `"${this.$store.state.me.Profile.Name}"`);
                    javascript = javascript.replaceAll('{{email}}', `"${this.$store.state.me.Email}"`);
                    javascript = javascript.replaceAll('{{user_id}}', `"${this.$store.state.me.ID}"`);
                } else {
                    javascript = javascript.replaceAll(/\{\{[a-z0-9_-]+\}\}/gi, null);
                }
                // wrap he custom JavaScript in try catch statement to prevent it from breaking the DOM from loading
                javascript = `try {${javascript}} catch (err) { if (console && console.warn) {console.warn('There was an error in your custom JavaScript', err)}}`;

                // add the custom JavaScript to the DOM
                const dynamicScript = document.createElement('script');
                dynamicScript.setAttribute('type', 'text/javascript');
                dynamicScript.setAttribute('data-id', 'customerJavaScriptInjection');
                dynamicScript.innerHTML = javascript;
                document.head.appendChild(dynamicScript);
            }
        },
    },
};
</script>
