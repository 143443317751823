










































import { get, isEmpty } from 'lodash';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoImage from '../../Atoms/co-image/CoImage.vue';

interface Label {
    imageURL?: string;
    color?: string;
    icon?: string;
}

export default {
    name: 'CoThumbnail',
    components: {
        CoIcon,
        CoImage,
    },
    props: {
        ImageURL: {
            type: String,
            default: '',
        },
        Link: {
            type: String,
            default: '',
        },
        Round: {
            type: Boolean,
            default: false,
        },
        Clickable: {
            type: Boolean,
            default: true,
        },
        contentType: {
            type: String,
            default: 'image',
            validator: (value: string) => ['image', 'user', 'page', 'event', 'color'].includes(value),
        },
        color: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: 'md',
            validator: (value: string) => ['xs', 'sm', 'ms', 'md', 'lg', 'xl', 'label'].includes(value),
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: Boolean,
            default: true,
        },
        label: {
            type: Object as () => Label,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
    },
    computed: {
        classes() {
            return {
                clickable: this.Clickable,
                deletable: this.deletable,
                round: this.Round,
                [`size-${this.size}`]: true,
                'has-label': this.label && !isEmpty(this.label) ? true : false,
            };
        },
        placeholderSize() {
            switch (this.size) {
                case 'label':
                    return '75%';
                case 'sm':
                    return '.6rem';
                case 'ms':
                    return '.67rem';
                default:
                    return '1rem';
            }
        },
    },
    methods: {
        get,
        isEmpty,
        click(event) {
            if (this.Link) {
                // stop the click event from propagating to the parent element
                event.stopPropagation();
                this.$router.push(this.Link);
                return;
            }

            if (this.Clickable) {
                this.$emit('click', event);
            }
        },
    },
};
