













































































































































































































import i18n from 'vue-i18n';
import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { get } from 'lodash';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';
import EventBus from '@/eventBus';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import Placeholder from '@tiptap/extension-placeholder';

export default {
    i18n: {
        messages: {
            en: {
                onlyAdminsCanChange: 'Your @:labels.accountsettings can only be changed by an administrator.',
                namechangeinfo: 'Note that this will change your profile URL.',
                emailinfo: 'This email address must be used to log in.',
                deleteUserAccount: 'Delete User Account',
                cancelMembershipFirst: 'You can only delete your account after your membership has ended.',
                accountDeletedInfo: 'Your account will be deleted after your membership expires.',
                undo: 'Undo',
                scheduledForDeletionConfirmationInfo:
                    'Your membership is valid until {0}. Do you really want your account to be automatically deleted after your membership expires?',
                immediateDeletionConfirmationInfo:
                    'If you continue, your account will be deleted immediately and all data will be lost. This action cannot be undone. Do you really want to delete your account now?',
                accountDeleteFinalWarning:
                    'Are you really sure you want to delete your account? This action cannot be undone.',
                cantDeleteAccount: 'You cannot delete your account as you are an admin of this community. {0}',
                'You can manage your administrators here.': 'You can manage your administrators here.',
                manageCoappAccount: 'Manage coapp Account',
                manageCoappAccountInfo:
                    'To manage your coapp subscription and billing, please visit the coapp "Manage" section.',
                manageSubscription: 'Manage Subscription',
            },
            de: {
                onlyAdminsCanChange:
                    'Deine @:labels.accountsettings können nur von einem/r Administrator/in geändert werden.',
                namechangeinfo: 'Bitte beachte, dass dies deine Profil-URL ändert.',
                emailinfo: 'Diese E-Mail wird zum Einloggen verwendet.',
                deleteUserAccount: 'Benutzerkonto löschen',
                cancelMembershipFirst: 'Du kannst dein Konto erst löschen, wenn deine Mitgliedschaft beendet ist.',
                accountDeletedInfo: 'Dein Konto wird nach Ablauf deiner Mitgliedschaft gelöscht.',
                undo: 'Widerrufen',
                scheduledForDeletionConfirmationInfo:
                    'Deine Mitgliedschaft ist bis zum {0} gültig. Möchtest du wirklich, dass dein Konto nach Ablauf deiner Mitgliedschaft automatisch gelöscht wird?',
                immediateDeletionConfirmationInfo:
                    'Wenn du fortfährst, wird dein Konto sofort gelöscht und alle Daten gehen verloren. Dieser Vorgang kann nicht rückgängig gemacht werden. Willst du dein Konto jetzt wirklich löschen?',
                accountDeleteFinalWarning:
                    'Bist du wirklich sicher, dass du dein Konto löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.',
                cantDeleteAccount: 'Du kannst dein Konto nicht löschen, da du ein Admin dieser Community bist. {0}',
                'You can manage your administrators here.': 'Du kannst deine Administratoren hier verwalten.',
                manageCoappAccount: 'coapp Konto verwalten',
                manageCoappAccountInfo:
                    'Um dein coapp-Abonnement und die Abrechnung zu verwalten, besuche bitte den coapp "Manage"-Bereich.',
                manageSubscription: 'Abonnement verwalten',
            },
        },
    },
    name: 'GeneralAccountSettings',
    components: {
        CoCard,
        CoText,
        CoButton,
        CoSelect,
        CoSkeleton,
        CoModal,
        CoConfirmation,
    },
    data() {
        return {
            savingEmail: false,
            saving: false,
            form: this.$store.state.me,
            secondForm: this.$store.state.me,
            currentUserFirstName: this.$store.state.me.Profile.FirstName,
            currentUserLastName: this.$store.state.me.Profile.LastName,
            currentEmail: this.$store.state.me.Email,

            membership: null,
            loadingMembershipData: true,
            deleting: false,
            loadingDelete: true,
            availableLanguages: [
                { Value: 'en-US', Name: this.$t('languages.en') },
                { Value: 'de-DE', Name: this.$t('languages.de') },
            ],
            selectedLanguage: null,
            savingLanguage: false,
        };
    },
    computed: {
        isSpaceAdmin: {
            get() {
                if (
                    this.$store.state.me &&
                    this.$store.state.me.Permissions &&
                    this.$store.state.me.Permissions.includes('space_admin')
                ) {
                    return true;
                }
                return false;
            },
        },

        currentLanguage: {
            get() {
                const language = get(this.$store.state, 'me.Language', this.$i18n.locale);
                if (language.includes('de')) {
                    return { Value: 'de-DE', Label: this.$t('languages.de') };
                }

                return { Value: 'en-US', Label: this.$t('languages.en') };
            },
        },
    },
    mounted() {
        if (this.form.Profile.FirstName === '' || this.form.Profile.FirstName == null) {
            const names = this.form.Profile.Name.split(' ');
            const firstName = names[0];
            this.form.Profile.FirstName = firstName;
            names.splice(0, 1);
            if (names.length > 1) {
                this.form.Profile.LastName = names.join(' ');
            } else {
                const lastName = names[0];
                this.form.Profile.LastName = lastName;
            }
        }

        this.getUserMembership();
    },
    methods: {
        get,
        selectLanguage(language) {
            this.selectedLanguage = language;
        },
        saveLanguage() {
            this.savingLanguage = true;
            this.$i18n.locale = this.selectedLanguage.Value;
            this.$language = this.selectedLanguage.Value;
            axios({
                method: 'PUT',
                url: '/me/language',
                withCredentials: true,
                data: {
                    language: this.selectedLanguage.Value,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$store.commit('GETME', response.data);
                        EventBus.$emit('INFO', {
                            Message: this.$t('labels.changessaved'),
                            Details: null,
                        });
                    }
                })
                .catch((error) => {
                    Bugsnag.notify(error);
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.savingLanguage = false;
                });
        },
        getMe() {
            this.$store
                .dispatch('getMe')
                .then(() => {
                    if (
                        this.$store.state.me.ScheduledForDeletion === true &&
                        this.$store.state.me.ScheduledForDeletionAt > 0
                    ) {
                        const message = {
                            Message: 'Account deletion scheduled',
                            Details: '',
                        };
                        EventBus.$emit('INFO', message);
                    } else {
                        const message = {
                            Message: 'Account deleted',
                            Details: '',
                        };
                        EventBus.$emit('INFO', message);

                        // logout
                        localStorage.removeItem('_authtoken');
                        this.$router.go('/login');
                    }
                })
                .catch(() => {
                    const message = {
                        Message: 'Account deleted',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);

                    // logout
                    localStorage.removeItem('_authtoken');
                    this.$router.go('/login');
                });
        },
        membershipCancelledToDate() {
            if (this.membership && this.membership.currentPlan && this.membership.currentPlan.cancelledAt > 0) {
                const str = this.$t('datetime', {
                    date: this.membership.currentPlan.cancelledAt,
                    customFormat: 'dd MMMM yyyy',
                });
                return str;
            }
            return false;
        },
        deleteUser() {
            this.deleting = true;
            if (!this.membershipCancelledToDate() && !confirm(this.$t('accountDeleteFinalWarning'))) {
                this.deleting = false;
                return false;
            }
            axios({
                method: 'DELETE',
                url: `/me`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        this.getMe();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', { Message: 'Could not delete account. Contact team.' });
                })
                .finally(() => {
                    this.deleting = false;
                });
        },
        undoDeleteSchedule() {
            this.deleting = true;
            axios({
                method: 'PUT',
                url: `/me/undo-delete-schedule`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        this.getMe();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', { Message: 'Could not undo account scheduled deletion. Contact team.' });
                })
                .finally(() => {
                    this.deleting = false;
                });
        },
        getUserMembership() {
            axios({
                method: 'GET',
                url: `/membership`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        this.membership = response.data;
                    }
                })
                .catch((error) => {
                    this.membership = {
                        status: 'UNSPECIFIED',
                        stripeCustomerLink: null,
                        stripeCustomerID: '',

                        currentBillingPeriodStart: null, // unix timestamp in seconds when the current billing period started
                        currentBillingPeriodEnd: null, // unix timestamp in seconds when the current billing period ends
                        billingCycleAnchor: null, // unix timestamp in seconds when the billing cycle anchor was set
                        cancelAt: null, // unix timestamp in seconds when the subscription will be canceled
                        canceledAt: null, // unix timestamp in seconds when the subscription was canceled
                        startDate: null, // unix timestamp in seconds when the subscription was created
                        endedAt: null, // unix timestamp in seconds when the subscription ended
                        created: null, // unix timestamp in seconds when the membership was created
                    };
                    console.log(error);
                })
                .finally(() => {
                    this.loadingMembershipData = false;
                });
        },
        saveChanges() {
            this.saving = true;
            this.form.Profile.Name = `${this.form.Profile.FirstName} ${this.form.Profile.LastName}`;
            this.form.Email = this.currentEmail;
            this.$store
                .dispatch('updateUser', this.form)
                .then(() => {
                    this.saving = false;
                    this.currentUserFirstName = this.form.Profile.FirstName;
                    this.currentUserLastName = this.form.Profile.LastName;
                    const message = {
                        Message: 'Name updated',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                })
                .catch(() => {
                    this.saving = false;
                    const message = {
                        Message: 'Could not update profile. Contact team.',
                    };
                    EventBus.$emit('ERROR', message);
                });
        },
        changeEmail() {
            this.savingEmail = true;
            const oldEmail = this.currentEmail;
            this.secondForm.Email = this.secondForm.Email.toLowerCase();
            this.$store
                .dispatch('updateEmail', this.secondForm)
                .then(() => {
                    this.savingEmail = false;
                    this.currentEmail = this.secondForm.Email;
                    const message = {
                        Message: 'Updated, check your inbox for a verification link',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                })
                .catch((error) => {
                    this.savingEmail = false;
                    this.secondForm.Email = oldEmail;
                    if (error.response && error.response.status && error.response.status === 410) {
                        const message = {
                            Message: 'Email already in use',
                        };
                        EventBus.$emit('ERROR', message);
                    } else {
                        const message = {
                            Message: 'Could not update profile. Contact team.',
                        };
                        EventBus.$emit('ERROR', message);
                    }
                });
        },
    },
};
