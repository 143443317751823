






















































import Vue from 'vue';
import axios from 'axios';
import get from 'lodash';
import { Event } from '@/components/Molecules/co-card-event/models.ts';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoCardEvent from '@/components/Molecules/co-card-event/CoCardEvent.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import EventBus from '@/eventBus';

export default {
    name: 'MyEvents',
    data() {
        return {
            events: [],
            pastEvents: [],
            me: this.$store.state.me,
            loading: false,
            pastLoading: false,
        };
    },
    components: {
        CoText,
        CoHeadline,
        CoCardEvent,
        CoRoundButton,
        CoButton,
        CoCard,
    },
    i18n: {
        messages: {
            en: {
                emptymessage: 'You are not attending an event yet.',
                pastevents: 'My past events',
            },
            de: {
                emptymessage: 'Du nimmst noch an keiner Veranstaltung teil.',
                pastevents: 'Meine vergangenen Veranstaltungen',
            },
        },
    },
    mounted() {
        this.getMyEvents();
        this.getMyPastEvents();
    },
    methods: {
        mapEventStructure(object) {
            const event = object.object;
            const spaceItem = object.space;
            const e: Event = {
                id: event.ID,
                title: event.Title,
                description: event.Description,
                start: event.StartDate,
                end: event.EndDate,
                slug: event.Slug,
                image: event.ImageURL,
            };

            const participants = event.Participants;
            if (participants && participants.includes(this.me.ID)) {
                e.attend = true;
            }

            if (spaceItem && spaceItem.ID !== this.$store.state.space.ID) {
                e.space = {
                    id: spaceItem.ID,
                    name: spaceItem.Name,
                    favicon: spaceItem.FaviconURL,
                    primaryColor: spaceItem.PrimaryColor,
                };
            }

            return e;
        },
        getMyEvents() {
            this.loading = true;
            axios({
                method: 'GET',
                url: 'event/list/my',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.Objects) {
                        response.data.Objects.forEach((entry) => {
                            const event = this.mapEventStructure(entry);
                            this.events.push(event);
                        });
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status !== 404) {
                        const msg = {
                            Message: 'Error while fetching events',
                            Details: '',
                        };
                        EventBus.$emit('ERROR', msg);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getMyPastEvents() {
            this.pastLoading = true;
            axios({
                method: 'GET',
                url: 'event/list/my?past=true',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.Objects) {
                        response.data.Objects.forEach((entry) => {
                            const event = this.mapEventStructure(entry);
                            this.pastEvents.push(event);
                        });
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status !== 404) {
                        const msg = {
                            Message: 'Error while fetching past events',
                            Details: '',
                        };
                        EventBus.$emit('ERROR', msg);
                    }
                })
                .finally(() => {
                    this.pastLoading = false;
                });
        },
    },
};
