











import axios from 'axios';
import { get, map, isArray } from 'lodash';
import SinglePostPage from '@/components/Templates/User/Post/SinglePostPage/SinglePostPage.vue';
import CoPost from '@/components/Organisms/co-post/CoPost.vue';

export default {
    name: 'CoSinglePost',
    components: {
        SinglePostPage,
        CoPost,
    },
    data() {
        return {
            post: {},
            loading: false,
        };
    },
    mounted() {
        const { slug } = this.$route.params;

        if (this.$route.name === 'Single Post View') {
            this.fetchPost(slug);
        } else if (this.$route.name === 'Single Page Update View') {
            this.fetchPageUpdate(slug);
        } else if (this.$route.name === 'Event as Post View') {
            this.fetchEvent(slug);
        } else if (this.$route.name === 'Page as Post View') {
            this.fetchPage(slug);
        } else if (this.$route.name === 'Market Item as Post View') {
            this.fetchMarketItem(slug);
        } else {
            this.$router.replace('/feed/error404');
        }
    },
    methods: {
        fetchPost(slug) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/post/by-slug/${slug}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data) {
                        this.post = {
                            object: response.data,
                            wasUpdated: false,
                            type: 'post',
                            id: response.data.ID,
                        };

                        this.post.object.Type = 'post';
                        let users = null;
                        try {
                            users = await axios({
                                method: 'POST',
                                url: '/user/listbyids',
                                data: {
                                    IDS: [
                                        {
                                            ID:
                                                response.data.AuthorID ||
                                                response.data.CreatedBy ||
                                                response.data.UserID,
                                        },
                                    ],
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });
                            users = map(users.data.Users, (user) => ({
                                ID: get(user, 'ID'),
                                Name: get(user, 'Profile.Name'),
                                Slug: get(user, 'Slug'),
                                ImageURL: get(user, 'Profile.ImageURL'),
                                Bio: get(user, 'Profile.Bio'),
                            }));
                        } catch (error) {
                            console.log(error);
                        }

                        if (response.data.AuthorID || response.data.CreatedBy || response.data.UserID) {
                            this.post.object.Author = users.find(
                                (user) =>
                                    user.ID === this.post.object.AuthorID ||
                                    user.ID === this.post.object.CreatedBy ||
                                    user.ID === this.post.object.UserID
                            );
                        }

                        const tmpimages =
                            get(this.post, 'object.Images', null) || get(this.post, 'object.ImageURLs', []);
                        this.post.object.Images = map(tmpimages, (i) => ({ ImageURL: i }));

                        if (get(this.post, 'object.ParentID', null)) {
                            this.post.object.ParentContent = {};
                            let fetch = {
                                ID: this.post.object.ParentID,
                                Type: 'event',
                            };

                            // post ParentType is 1 for event, 2 for project, 3 for job, and 0 for undefined
                            // since we starting with events consider 0 as event, other types will be added later

                            if (this.post.object.ParentType === 1) {
                                fetch.Type = 'event';
                            } else if (this.post.object.ParentType === 2) {
                                fetch.Type = 'project';
                            } else if (this.post.object.ParentType === 3) {
                                fetch.Type = 'job';
                            } else {
                                fetch.Type = 'event';
                            }

                            this.post.object.ParentContent.Fetch = fetch;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchPageUpdate(slug) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/project/update/by-id/${slug}`, // its an id not slug
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data) {
                        this.post = {
                            object: response.data,
                            wasUpdated: false,
                            type: 'project-update',
                        };

                        this.post.object.Type = 'project-update';
                        let users = null;
                        try {
                            users = await axios({
                                method: 'POST',
                                url: '/user/listbyids',
                                data: {
                                    IDS: [
                                        {
                                            ID:
                                                response.data.AuthorID ||
                                                response.data.CreatedBy ||
                                                response.data.UserID,
                                        },
                                    ],
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });
                            users = map(users.data.Users, (user) => ({
                                ID: get(user, 'ID'),
                                Name: get(user, 'Profile.Name'),
                                Slug: get(user, 'Slug'),
                                ImageURL: get(user, 'Profile.ImageURL'),
                                Bio: get(user, 'Profile.Bio'),
                            }));
                        } catch (error) {
                            console.log(error);
                        }

                        if (response.data.AuthorID || response.data.CreatedBy || response.data.UserID) {
                            this.post.object.Author = users.find(
                                (user) =>
                                    user.ID === this.post.object.AuthorID ||
                                    user.ID === this.post.object.CreatedBy ||
                                    user.ID === this.post.object.UserID
                            );
                        }

                        this.post.object.ParentContent = {};
                        this.post.object.ParentContent.Fetch = {
                            ID: this.post.object.ProjectID,
                            Type: 'project',
                        };
                        this.post.object.CreatedAt = this.post.object.Created;

                        const tmpimages =
                            get(this.post, 'object.Images', null) || get(this.post, 'object.ImageURLs', []);
                        this.post.object.Images = map(tmpimages, (i) => ({ ImageURL: i }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchEvent(slug) {
            this.loading = true;

            axios
                .get(`/event/by-slug/${slug}`)
                .then(async (response) => {
                    this.post = {
                        object: response.data,
                        wasUpdated: false,
                        type: 'event',
                        id: response.data.ID,
                    };

                    const tmpimages = get(this.post, 'object.Images', null) || get(this.post, 'object.ImageURLs', []);
                    this.post.object.Images = null;

                    // eslint-disable-next-line no-unused-expressions
                    this.post.object.Text ? null : (this.post.object.Text = null);

                    this.post.object.ParentContent = {
                        ...this.post.object,
                        Type: this.post.type,
                        Images: map(tmpimages, (i) => ({ ImageURL: i })),
                    };

                    this.post.object.Type = 'event';
                    let users = null;
                    try {
                        users = await axios({
                            method: 'POST',
                            url: '/user/listbyids',
                            data: {
                                IDS: [
                                    {
                                        ID: response.data.AuthorID || response.data.CreatedBy || response.data.UserID,
                                    },
                                ],
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        users = map(users.data.Users, (user) => ({
                            ID: get(user, 'ID'),
                            Name: get(user, 'Profile.Name'),
                            Slug: get(user, 'Slug'),
                            ImageURL: get(user, 'Profile.ImageURL'),
                            Bio: get(user, 'Profile.Bio'),
                        }));
                    } catch (error) {
                        console.log(error);
                    }

                    if (response.data.AuthorID || response.data.CreatedBy || response.data.UserID) {
                        this.post.object.Author = users.find(
                            (user) =>
                                user.ID === this.post.object.AuthorID ||
                                user.ID === this.post.object.CreatedBy ||
                                user.ID === this.post.object.UserID
                        );
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchPage(slug) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/project/by-slug/${slug}`,
            })
                .then(async (response) => {
                    this.post = {
                        object: response.data,
                        wasUpdated: false,
                        type: 'project',
                        id: response.data.ID,
                    };

                    const tmpimages = get(this.post, 'object.Images', null) || get(this.post, 'object.ImageURLs', []);
                    this.post.object.Images = null;

                    // eslint-disable-next-line no-unused-expressions
                    this.post.object.Text ? null : (this.post.object.Text = null);

                    this.post.object.ParentContent = {
                        ...this.post.object,
                        Type: this.post.type,
                    };

                    this.post.object.Type = 'project';

                    this.post.object.ParentContent.Images = isArray(this.post.object.ParentContent.ImageURL)
                        ? map(this.post.object.ImageURL, (i) => {
                              if (typeof i === 'string' && i.match('/img/Platform_Gradients')) {
                                  return false;
                              }
                              return { ImageURL: i };
                          })
                        : null;
                    this.post.object.CreatedAt = this.post.object.Created;

                    let users = null;
                    try {
                        users = await axios({
                            method: 'POST',
                            url: '/user/listbyids',
                            data: {
                                IDS: [
                                    {
                                        ID: response.data.AuthorID || response.data.CreatedBy || response.data.UserID,
                                    },
                                ],
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        users = map(users.data.Users, (user) => ({
                            ID: get(user, 'ID'),
                            Name: get(user, 'Profile.Name'),
                            Slug: get(user, 'Slug'),
                            ImageURL: get(user, 'Profile.ImageURL'),
                            Bio: get(user, 'Profile.Bio'),
                        }));
                    } catch (error) {
                        console.log(error);
                    }

                    if (response.data.AuthorID || response.data.CreatedBy || response.data.UserID) {
                        this.post.object.Author = users.find(
                            (user) =>
                                user.ID === this.post.object.AuthorID ||
                                user.ID === this.post.object.CreatedBy ||
                                user.ID === this.post.object.UserID
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchMarketItem(slug) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/job/${slug}`, // its an id not slug
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data) {
                        this.post = {
                            object: response.data,
                            wasUpdated: false,
                            type: 'job',
                        };

                        this.post.object.Type = 'job';
                        let users = null;
                        try {
                            users = await axios({
                                method: 'POST',
                                url: '/user/listbyids',
                                data: {
                                    IDS: [
                                        {
                                            ID:
                                                response.data.AuthorID ||
                                                response.data.CreatedBy ||
                                                response.data.UserID,
                                        },
                                    ],
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });
                            users = map(users.data.Users, (user) => ({
                                ID: get(user, 'ID'),
                                Name: get(user, 'Profile.Name'),
                                Slug: get(user, 'Slug'),
                                ImageURL: get(user, 'Profile.ImageURL'),
                                Bio: get(user, 'Profile.Bio'),
                            }));
                        } catch (error) {
                            console.log(error);
                        }

                        // assign author infos
                        if (response.data.AuthorID || response.data.CreatedBy || response.data.UserID) {
                            this.post.object.Author = users.find(
                                (user) =>
                                    user.ID === this.post.object.AuthorID ||
                                    user.ID === this.post.object.CreatedBy ||
                                    user.ID === this.post.object.UserID
                            );
                        }

                        // assign space infos
                        // eslint-disable-next-line no-unused-expressions
                        this.post.space ? (this.post.object.Space = this.post.space) : null;
                        // assign images for posts and page-updates (project-updates)
                        const tmpimages =
                            get(this.post, 'object.Images', null) || get(this.post, 'object.ImageURLs', []);
                        this.post.object.Images = map(tmpimages, (i) => ({ ImageURL: i }));
                        // map type
                        this.post.object.Type = this.post.type;

                        // generate post text for non post content
                        // eslint-disable-next-line no-unused-expressions
                        this.post.object.Text ? null : (this.post.object.Text = null);
                        // parent content default mapping
                        this.post.object.ParentContent = {
                            ...this.post.object,
                            Type: this.post.type,
                        };

                        this.post.object.Images = null;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
