import CoNotification from './coNotification.vue';

export default {
    title: 'Organisms/CoNotification',
    component: CoNotification,
    argTypes: {
        item: {
            type: 'object',
        },
    },
    parameters: {
        backgrounds: {
            default: 'dark',
        },
        mockData: [
            {
                method: 'POST',
                url: '/user/listbyids',
                status: 200,
                response: (request) => {
                    return {
                        Users: [
                            {
                                ID: 'AHYqcGmtcea2aEKPuVEsWlAZl3HCjJ4QpOp-8fRUrLiihgRy2avnJpkPNpbpyPBN9glt0Tyk7u9OzBq-CW2rag==',
                                Slug: 'chat-member-AHYqcGmtce',
                                Profile: {
                                    Name: 'Onboarding Olivia',
                                    FirstName: 'Onboarding',
                                    LastName: 'Olivia',
                                    Salutation: 'f',
                                    Since: '1691096657',
                                    ImageURL:
                                        'https://collaborates.s3.eu-central-1.amazonaws.com/websummit.coapp.io/user-profile-image/HUCCECIAYV',
                                },
                                SpaceID: 'localhost:8080',
                                Address: {
                                    Address: 'asd',
                                    PostalCode: 'asd',
                                    City: 'asd',
                                    Country: 'Germany',
                                },
                                RegistrationAddress: {},
                                LastActivity: '1691101592',
                                Type: 'User',
                            },
                        ],
                    };
                },
            },
            {
                method: 'GET',
                url: '/post/by-id/:id',
                status: 200,
                response: (request) => {
                    return {
                        ID: '6UW0fVGvnAns6Zd0dVDgU1C5CauBWWRhIfpGdDRhvYbWXuZP16l9EMfvg9yTYbJiOUtTKZU-1xI9KV4_-_XDow==',
                        Slug: 'p-span-data-ty-6UW0fVGvnA',
                        AuthorID:
                            'AHYqcGmtcea2aEKPuVEsWlAZl3HCjJ4QpOp-8fRUrLiihgRy2avnJpkPNpbpyPBN9glt0Tyk7u9OzBq-CW2rag==',
                        Text: '\u003cp\u003e\u003cspan data-type="mention" class="mention" data-id="jonas-test-8jBzreMouk" data-label="Jonas Test"\u003e@Jonas Test\u003c/span\u003e mention test\u003c/p\u003e',
                        Mentions: ['jonas-test-8jBzreMouk', 'jonas-test-8jBzreMouk'],
                        CreatedAt: '1691172732',
                        UpdatedAt: '1691172732',
                        Type: 'Post',
                        SpaceID: 'localhost:8080',
                        Version: '1',
                        Reactions: {
                            '👍': {
                                Users: [
                                    {
                                        ID: '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
                                        Name: 'Jonas Test',
                                    },
                                ],
                            },
                        },
                    };
                },
            },
            {
                method: 'GET',
                url: `/project/update/by-id/:id`,
                status: 200,
                response: (request) => {
                    return {
                        ID: 'xXzk3SqDU83MVpgIvL5bgptSuswqIQfHdzLyhH-uugRA_4m-9zxf4gdHHJ5w3caIpfXXnMlVEVbFS5COreCsZQ==',
                        ProjectID:
                            'P-iET7B1_KV9KSfOzR-c-HeSPmY1u7t7GAdjtv7in0TMlfJE_kKpU0Id4JHLqm6-kNzWYngxRPaQ59Fpqb9D0A==',
                        Text: '\u003cp\u003epage update\u003c/p\u003e',
                        UserID: '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
                        Created: '1690388239',
                        SpaceID: 'localhost:8080',
                        Version: '1',
                        Reactions: {
                            '👍': {
                                Users: [
                                    {
                                        ID: 'AHYqcGmtcea2aEKPuVEsWlAZl3HCjJ4QpOp-8fRUrLiihgRy2avnJpkPNpbpyPBN9glt0Tyk7u9OzBq-CW2rag==',
                                        Name: 'Chat Member',
                                    },
                                ],
                            },
                        },
                    };
                },
            },
            {
                method: 'POST',
                url: '/project/listbyids',
                status: 200,
                response: (request) => {
                    return {
                        Projects: [
                            {
                                ID: 'P-iET7B1_KV9KSfOzR-c-HeSPmY1u7t7GAdjtv7in0TMlfJE_kKpU0Id4JHLqm6-kNzWYngxRPaQ59Fpqb9D0A==',
                                Slug: 'new-test-page-P-iET7B1_K',
                                UserID: '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
                                Title: 'new test page new test page new test page new test page',
                                Description: 'my page description',
                                ImageURL: ['/img/Platform_Gradients-7.af34b3a5.jpg'],
                                Contributor: [
                                    '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
                                ],
                                Owner: [
                                    '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
                                ],
                                Created: '1690387850',
                                LastModifyed: '1691100307',
                                Published: true,
                                Type: 'Project',
                                SpaceID: 'localhost:8080',
                                PublishedAt: 1690387876,
                                ChannelID:
                                    '21d794208fad0a33bb635b40333f30441bdabd8387fdbd95f57b7ae748a7cceb70ccfc848eb96fd2f20b8cde6b84796770cea76c6b37c6bf5562ebdecb26adeb',
                            },
                        ],
                    };
                },
            },
            {
                method: 'GET',
                url: `/job/by-id/:id`,
                status: 200,
                response: (request) => {
                    return {
                        ID: 'V5beIwHc8bTH7sQVPCzZN1B2z3U02GP8brMj6DA2MePJzkQW_RBQJZKa2ha1Yf87u2pWrQxkDHNF8GkhAZkM-w==',
                        Title: 'asdas',
                        Summary: 'asd',
                        Description: 'asd',
                        CreatedAt: 1690474357,
                        LastUpdate: 1690534944,
                        SpaceID: 'localhost:8080',
                        Published: true,
                        Slug: 'asdas-V5beIwHc8b',
                        AuthorID:
                            '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
                        Success: true,
                        FoundOnPlatform: true,
                    };
                },
            },
            {
                method: 'POST',
                url: '/notification',
                status: 200,
                response: (request) => {
                    return true;
                },
            },
        ],
    },
};

// More on component templates: https://storybook.js.org/docs/vue/writing-stories/introduction#using-args
const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { CoNotification },
    template: `<CoNotification v-bind="$props" />`,
});

// More on args: https://storybook.js.org/docs/vue/writing-stories/args
export const PostComment = Template.bind({});
PostComment.args = {
    item: {
        ID: 'hvs4ik6q40gvAJSe5XLs4z9jUdfdIjgjw1-as_3aWQwFfm7HferwT3hwHfov_wQVfjUjnA4riTkzTD4Civ98Fw==',
        Type: 'info',
        Event: {
            ID: 'xcPpaaMsEKy-9Ifqcp_o4S10CrjGSOTDCFvHt1U4hWWIY0oueuMqzXG2Rjhqskl0WGiY-JoI0ZUGOmOVMa35mg==',
            InitiatorID: 'AHYqcGmtcea2aEKPuVEsWlAZl3HCjJ4QpOp-8fRUrLiihgRy2avnJpkPNpbpyPBN9glt0Tyk7u9OzBq-CW2rag==',
            InitiatorType: 'user',
            ObjectType: 'comment',
            ObjectID: 'Uatxqy_WvR4o99gZk_bSA-kApvbWlw-Nt7kvVuXsDyuBMbo5yR_tEvB5X2xZPRyN-tvDbMhtzxl1O7dHy7PmTg==',
            Type: 'comment-create',
            TargetType: 'post',
            TargetID: '6UW0fVGvnAns6Zd0dVDgU1C5CauBWWRhIfpGdDRhvYbWXuZP16l9EMfvg9yTYbJiOUtTKZU-1xI9KV4_-_XDow==',
            Timestamp: 1692869488,
            SpaceID: 'localhost:8080',
        },
        Recipient: '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
        CreatedAt: 1692869488,
        SpaceID: 'localhost:8080',
    },
};
export const PostReactionCreated = Template.bind({});
PostReactionCreated.args = {
    item: {
        ID: 'hvs4ik6q40gvAJSe5XLs4z9jUdfdIjgjw1-as_3aWQwFfm7HferwT3hwHfov_wQVfjUjnA4riTkzTD4Civ98Fw==',
        Type: 'info',
        Event: {
            ID: 'xcPpaaMsEKy-9Ifqcp_o4S10CrjGSOTDCFvHt1U4hWWIY0oueuMqzXG2Rjhqskl0WGiY-JoI0ZUGOmOVMa35mg==',
            InitiatorID: 'AHYqcGmtcea2aEKPuVEsWlAZl3HCjJ4QpOp-8fRUrLiihgRy2avnJpkPNpbpyPBN9glt0Tyk7u9OzBq-CW2rag==',
            InitiatorType: 'user',
            ObjectType: 'reaction',
            ObjectID: 'Uatxqy_WvR4o99gZk_bSA-kApvbWlw-Nt7kvVuXsDyuBMbo5yR_tEvB5X2xZPRyN-tvDbMhtzxl1O7dHy7PmTg==',
            Type: 'reaction-created',
            TargetType: 'post',
            TargetID: '6UW0fVGvnAns6Zd0dVDgU1C5CauBWWRhIfpGdDRhvYbWXuZP16l9EMfvg9yTYbJiOUtTKZU-1xI9KV4_-_XDow==',
            Timestamp: 1692869488,
            SpaceID: 'localhost:8080',
        },
        Recipient: '8jBzreMoukynawz4BeUFz_02v6O99WgLd9JMfp4A_r--nowwcxzQN2qTGevf7CeYWZgx5mMzzrsOgRzzw8hUVA==',
        CreatedAt: 1692869488,
        SpaceID: 'localhost:8080',
    },
};
export const MentionInPost = Template.bind({});
MentionInPost.args = {
    item: {
        ID: 'c294fd09-f338-4948-b308-b1d7977ddd05',
        Type: 'Info',
        Event: {
            ID: '554d8e9b-c900-4794-9388-20ced5c67a6e',
            InitiatorID: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
            InitiatorType: 'user',
            ObjectType: 'user_mention',
            ObjectID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
            Type: 'mention-in-post',
            TargetType: 'projectupdate',
            TargetID: 'JWEo_kvDMFU_zM-k0TuCmb6bPa4Fn7-p7_ZjFGZENiqsHmC5dgaITrpU7b1IfbVSKajQdIRM0wDKso7S1Q95kw==',
            Timestamp: 1721680466,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        },
        Recipient: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        Seen: true,
        CreatedAt: 1721680466,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721680466',
    },
};

export const MentionInComment = Template.bind({});
MentionInComment.args = {
    item: {
        ID: '11b0696d-bf3f-4683-837c-b3627de317b1',
        Type: 'Info',
        Event: {
            InitiatorID: 'OVwoLCLNl26IwkMo6a6weFMxmYZ-VSisyZHM1OuM7xJvHqH5MgxvyaNwdpamwuOJ_O918OreUZZDcADs2fWADg==',
            InitiatorType: 'user',
            ObjectType: 'comment',
            ObjectID: 'KjtybKM_qG3z1aOl_Vz0_IFmoMTJHE8tVuIA0JiTP_KXvyCAWUyOrrNrO668n1WoR7cmdQ1Pi8NzpxHzhHUPfQ==',
            Type: 'mention-in-comment',
            TargetType: 'page',
            TargetID: 'lb2bRwpfLFRTA8MO9-up7KjPFTSA_BfLAMtJhzNOW89PJneCf9Y-YlcNy0W93pzzfyxhk8KlvWCVscGdEQ0eaA==',
            Timestamp: 1720046764,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        },
        Recipient: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        Seen: true,
        CreatedAt: 1720046774,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1720046774',
    },
};

export const NewActivityOnPost = Template.bind({});
NewActivityOnPost.args = {
    item: {
        ID: '87c0a6bf-c698-4f11-b200-e623fcc8dfae',
        Type: 'Info',
        Event: {
            InitiatorID: 'ie_hgt70k6lMWoB2QieI-O0UH1GiTjeErBMrAeELoBiDfNdaS_CKUtw1CmVNZx2rf9V0SkDode-v5r2qZke4Ng==',
            InitiatorType: 'user',
            ObjectType: 'comment',
            ObjectID: 'daFW0Edy1nUiQC_YQofYa02HQPobXFFFAy7YneXI99ypwtKXVS4pHqFyziwfhQKef3IsOr83Jy9UiXqBOzJ3vQ==',
            Type: 'new-activity-on-post',
            TargetType: 'projectupdate',
            TargetID: 'Pw7B7lSVk3KbSnjI_JNVUzs8zT4W-6zqADrowTA8Ur2sgUdYLxJeNVC4cyWBosmZotscG6oZ8ECbAfeZSxW4OQ==',
            Timestamp: 1721639209,
            SpaceID: 'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5',
            RecipientUserID: 'HHOWpaPgO93iQ8g2mKHW-7nJ0amz4ElfhNtClbqx7fby-9tKm3NkRo_f5iCJp7mE27wS0NxzvoLyUbBYQThynA==',
        },
        Recipient: 'HHOWpaPgO93iQ8g2mKHW-7nJ0amz4ElfhNtClbqx7fby-9tKm3NkRo_f5iCJp7mE27wS0NxzvoLyUbBYQThynA==',
        Seen: true,
        CreatedAt: 1721639230,
        SpaceID: 'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5',
        Timestamp: '1721639230',
    },
};

export const NewEventRSVP = Template.bind({});

NewEventRSVP.args = {
    item: {
        ID: '71490ae5-9a34-47d1-bf68-1e6897361bc4',
        Type: 'Info',
        Event: {
            InitiatorID: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
            InitiatorType: 'user',
            Type: 'new-event-participant',
            TargetType: 'event',
            TargetID: 'JeznOy-rB73c6EqUTqORdP9-5yIe8FquQjbeevCk6IA0I_zI0bkP19wGc2v_LNgGcTPuJRVOrkjeSBLBXgTzKg==',
            Timestamp: 1721068626,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        },
        Recipient: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        Seen: true,
        CreatedAt: 1721068626,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721068626',
    },
};

export const NewPageFollowed = Template.bind({});
NewPageFollowed.args = {
    item: {
        ID: '87a8c834-c360-4f46-b62d-68128a2b37a2',
        Type: 'Info',
        Event: {
            ID: '38bd2e2e-89ba-4ac4-a5d3-a329e6ed9735',
            InitiatorID: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
            InitiatorType: 'user',
            Type: 'new-page-follower',
            TargetType: 'page',
            TargetID: '6ay2vS8mzxLeY3nbwN3NM8cLWN0cry1Diu1yyI1d8LNphRFQDw5xBX8i-MT3-xKETjs4PfBUPKEGGIchrkDHzw==',
            Timestamp: 1721769956,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        },
        Recipient: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        Seen: true,
        CreatedAt: 1721769956,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721769956',
    },
};

export const HasBeenAddAsOwner = Template.bind({});
HasBeenAddAsOwner.args = {
    item: {
        ID: '48e0d3d7-cff9-4097-a520-f6c7167945ab',
        Type: 'Info',
        Event: {
            InitiatorType: 'user',
            Type: 'new-owner-of-page',
            TargetType: 'page',
            TargetID: 'IWCxJbOZATXpR2tug63omjdfmtx4zyd2gn24oxoXdKzxL29TyH6uj4kHZmCIyUTd7arOklb1uT242lEiYBa3cw==',
            Timestamp: 1721680646,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        },
        Recipient: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        Seen: true,
        CreatedAt: 1721680646,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721680646',
    },
};

export const HasBeenAddAsContributor = Template.bind({});
HasBeenAddAsContributor.args = {
    item: {
        ID: '48e0d3d7-cff9-4097-a520-f6c7167945ab',
        Type: 'Info',
        Event: {
            InitiatorType: 'user',
            Type: 'new-contributor-of-page',
            TargetType: 'page',
            TargetID: 'IWCxJbOZATXpR2tug63omjdfmtx4zyd2gn24oxoXdKzxL29TyH6uj4kHZmCIyUTd7arOklb1uT242lEiYBa3cw==',
            Timestamp: 1721680646,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        },
        Recipient: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        Seen: true,
        CreatedAt: 1721680646,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721680646',
    },
};

export const Reaction = Template.bind({});
Reaction.args = {
    item: {
        ID: '7943306e-b942-45d7-b82d-22429faf8ef4',
        Type: 'Info',
        Event: {
            ID: '0fb9dcc9-376e-4f1e-8448-fd23d89bbd1b',
            InitiatorID: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
            InitiatorType: 'user',
            ObjectType: 'reaction',
            ObjectID: '😍',
            Type: 'reaction-to-post',
            TargetType: 'post',
            TargetID: 'Jet5FdvJS6BBv6UWWD6eUmCtBL73c_ln6DTpSL-Z_tDxfT59t341opSp6oY0eBpoyOzrGtfAsOfD6x7tny0vNA==',
            Timestamp: 1721723000,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        },
        Recipient: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
        Seen: true,
        CreatedAt: 1721769798,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721769798',
    },
};

export const NewPageUpdate = Template.bind({});
NewPageUpdate.args = {
    item: {
        ID: 'e5c8180e-5cd9-4557-8260-a70300d7a295',
        Type: 'Info',
        Event: {
            ID: '9432c418-eefe-47e4-9274-d887aa6fa4b9',
            InitiatorID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
            InitiatorType: 'user',
            ObjectType: 'page-update',
            ObjectID: 'L3FG1nqHiqeEsqAFhKnNiraK1Z4qyjQM6G3Ba85R33fo1sn_A1M9QGqkCwPEZ5Y4N_RTHpOHJJ4hlM_yt17i1w==',
            Type: 'new-update-in-page-i-follow',
            TargetType: 'page',
            TargetID: '6ay2vS8mzxLeY3nbwN3NM8cLWN0cry1Diu1yyI1d8LNphRFQDw5xBX8i-MT3-xKETjs4PfBUPKEGGIchrkDHzw==',
            Timestamp: 1721769966,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
        },
        Recipient: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
        Seen: true,
        CreatedAt: 1721769966,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721769966',
    },
};

export const loading = Template.bind({});
loading.args = {
    loading: true,
    item: {
        ID: 'e5c8180e-5cd9-4557-8260-a70300d7a295',
        Type: 'Info',
        Event: {
            ID: '9432c418-eefe-47e4-9274-d887aa6fa4b9',
            InitiatorID: 'sLPUNxi5EhJpT7o655kDoYu6f7Bk7FBd_-solY9biSU19ziDRN47fvnLARgS1IRvWDC9v-L7a-eBoa5EGxTWFg==',
            InitiatorType: 'user',
            ObjectType: 'page-update',
            ObjectID: 'L3FG1nqHiqeEsqAFhKnNiraK1Z4qyjQM6G3Ba85R33fo1sn_A1M9QGqkCwPEZ5Y4N_RTHpOHJJ4hlM_yt17i1w==',
            Type: 'new-update-in-page-i-follow',
            TargetType: 'page',
            TargetID: '6ay2vS8mzxLeY3nbwN3NM8cLWN0cry1Diu1yyI1d8LNphRFQDw5xBX8i-MT3-xKETjs4PfBUPKEGGIchrkDHzw==',
            Timestamp: 1721769966,
            SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
            RecipientUserID: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
        },
        Recipient: '9sB-s0xJbu7g1XF5Dhz7wXJmfMROsN-9TeWAwOsDEEfFc4CmnXRIdhDxDRpPWpd37QAWWelSBOUxotiBfpRhog==',
        Seen: true,
        CreatedAt: 1721769966,
        SpaceID: '3e0VvnjaIoEnY1Wke3LiO8Z8bqmXbV7LjXFehIY4CZEvC9iKjMdCk7bpAu8FGpFrDYhBq2IdhlRPqklwa4hCvg==',
        Timestamp: '1721769966',
    },
};
