var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co input position-relative",class:{
        'has-icon': _vm.icon,
        'is-password': _vm.type === 'password',
        readonly: _vm.readonly,
        disabled: _vm.disabled,
    }},[(_vm.label)?_c('CoLabel',{staticClass:"d-block",attrs:{"text":_vm.label}}):_vm._e(),_c('div',{staticClass:"d-flex"},[(_vm.icon)?_c('CoIcon',{staticClass:"icon align-self-center",attrs:{"name":_vm.icon}}):_vm._e(),((_vm.passwordRevealed ? 'text' : _vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],ref:"coInputElem",staticClass:"align-self-center",class:( _obj = {
                'has-icon': _vm.icon,
                'is-password': _vm.type === 'password',
                'bg-grey': _vm.greyTheme,
                mobile: _vm.isMobile && _vm.reduce
            }, _obj[_vm.background] = true, _obj['no-border'] =  _vm.noBorder, _obj ),style:({ width: _vm.width }),attrs:{"enterkeyhint":_vm.enterkeyhint,"name":(_vm.name || _vm.label || '').replace(/\W/g, '_').toLowerCase(),"placeholder":_vm.placeholder,"readonly":_vm.readonly,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":_vm.step,"pattern":_vm.type === 'number' ? '[0-9]+([\.,][0-9]+)?' : null,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.content)?_vm._i(_vm.content,_vm.value)>-1:(_vm.content)},on:{"input":_vm.handleInput,"change":[function($event){var $$a=_vm.content,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.content=$$a.concat([$$v]))}else{$$i>-1&&(_vm.content=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.content=$$c}},_vm.handleChange],"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter($event)},"keydown":_vm.handleKeyDown}}):((_vm.passwordRevealed ? 'text' : _vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],ref:"coInputElem",staticClass:"align-self-center",class:( _obj$1 = {
                'has-icon': _vm.icon,
                'is-password': _vm.type === 'password',
                'bg-grey': _vm.greyTheme,
                mobile: _vm.isMobile && _vm.reduce
            }, _obj$1[_vm.background] = true, _obj$1['no-border'] =  _vm.noBorder, _obj$1 ),style:({ width: _vm.width }),attrs:{"enterkeyhint":_vm.enterkeyhint,"name":(_vm.name || _vm.label || '').replace(/\W/g, '_').toLowerCase(),"placeholder":_vm.placeholder,"readonly":_vm.readonly,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":_vm.step,"pattern":_vm.type === 'number' ? '[0-9]+([\.,][0-9]+)?' : null,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.content,_vm.value)},on:{"input":_vm.handleInput,"change":[function($event){_vm.content=_vm.value},_vm.handleChange],"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter($event)},"keydown":_vm.handleKeyDown}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],ref:"coInputElem",staticClass:"align-self-center",class:( _obj$2 = {
                'has-icon': _vm.icon,
                'is-password': _vm.type === 'password',
                'bg-grey': _vm.greyTheme,
                mobile: _vm.isMobile && _vm.reduce
            }, _obj$2[_vm.background] = true, _obj$2['no-border'] =  _vm.noBorder, _obj$2 ),style:({ width: _vm.width }),attrs:{"enterkeyhint":_vm.enterkeyhint,"name":(_vm.name || _vm.label || '').replace(/\W/g, '_').toLowerCase(),"placeholder":_vm.placeholder,"readonly":_vm.readonly,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":_vm.step,"pattern":_vm.type === 'number' ? '[0-9]+([\.,][0-9]+)?' : null,"type":_vm.passwordRevealed ? 'text' : _vm.type},domProps:{"value":_vm.value,"value":(_vm.content)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},_vm.handleInput],"change":_vm.handleChange,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter($event)},"keydown":_vm.handleKeyDown}}),(_vm.type === 'password' && !_vm.passwordRevealed)?_c('CoIcon',{staticClass:"icon align-self-center",staticStyle:{"cursor":"pointer"},attrs:{"name":"eye"},nativeOn:{"click":function($event){_vm.passwordRevealed = !_vm.passwordRevealed}}}):(_vm.type === 'password' && _vm.passwordRevealed)?_c('CoIcon',{staticClass:"icon align-self-center",staticStyle:{"cursor":"pointer"},attrs:{"name":"eye-slash"},nativeOn:{"click":function($event){_vm.passwordRevealed = !_vm.passwordRevealed}}}):_vm._e()],1),(_vm.suggester && (_vm.suggestionsLoading || _vm.suggestions.length > 0))?_c('CoDropdown',{staticClass:"co-dropdown-suggestions position-absolute",style:(_vm.suggestionsLoading ? 'line-height: 0;' : ''),attrs:{"loading":_vm.suggestionsLoading,"tippyOptions":{
            showOnCreate: true,
            hideOnClick: false,
        }}},_vm._l((_vm.suggestions),function(suggestion,index){return _c('CoDropdownItem',{attrs:{"title":suggestion.label,"icon":suggestion.icon ? suggestion.icon : null,"thumbnail":suggestion.thumbnail ? suggestion.thumbnail : null,"active":_vm.selectedItem === index},on:{"click":function($event){return _vm.handleSelect(suggestion)}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }