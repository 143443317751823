




























import CoButton from '../../Atoms/co-button/CoButton.vue';
export default {
    name: 'CoForm',
    components: {
        CoButton,
    },
    props: {
        hideReset: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        submitLabel: {
            type: String,
            required: false,
            default: 'Submit',
        },
        submitBtnVariant: {
            type: String,
            required: false,
            default: 'primary',
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            form: null,
        };
    },
    methods: {
        submit() {
            //handler to programmatically submit the form from outside the component
            this.$refs.form.submit();
        },
        handleSubmit(event: SubmitEvent) {
            const form = new FormData(event.target as HTMLFormElement);
            let formData = {};
            for (const entry of form.entries()) {
                formData[entry[0]] = entry[1];
            }
            this.$emit('submit', formData);
        },
    },
};
